<template>
    <div class="i-surveys-form-radio">
        <form-item v-bind="$attrs" ref="formItem">
            <van-field :name="itemKey" :rules="rules">
                <template #input>
                    <van-radio-group v-model="modelValue" :disabled="isDisabled">
                        <div
                          class="radio_item_box"
                          v-for="(item, index) in options"
                          :key="`radio_${new Date().getTime()}_${index}`"
                        >
                          <van-radio :name="index">{{ item.name }}{{ item.append || '' }} <span class="file_code" v-if="optionType && item.fileUrl" @click.stop="clickShowFile(item)">附件</span></van-radio>
                          <!-- 输入框 -->
                          <div v-if="isShowOtherContent(item, index, 'input')" class="i-surveys-form-input" style="margin: 10px 0;">
                            <van-field :value="item.ext.indexValue" @input="inputVal($event, index)" @focus="inputFocus($event, item.ext.indexValue)" @blur="inputBlur($event, index)" :disabled="isDisabled" :rules="item.ext.inputMustAnswer && modelValue === index? [{required: true,message:'必填项不能为空',trigger: 'onBlur',}]: []" placeholder="请输入" style="padding: 9px 14px;">
                              <template #extra>{{item.ext.suffixName}}</template>
                            </van-field>
                          </div>
                          <div v-if="isShowOtherContent(item, index, 'img')">
                            <form-upload v-model="item.ext.indexValue" :disabled="isDisabled" :rules="item.ext.imageMustAnswer && modelValue === index? [{required: true,message:'必填项不能为空',trigger: 'onBlur',}]: []" />
                          </div>
                        </div>
                    </van-radio-group>
                </template>
            </van-field>
        </form-item>
    </div>
</template>

<script>
import FormItem from './form-item.vue'
import FormUpload from './form-upload.vue'
export default {
    components: { FormItem, FormUpload },
    data: () => ({
      inputValue: ''
    }),
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        options: {
            type: Array,
            default: () => ([])
        },
        rules: {
            type: Array,
            default: () => ([])
        },
        itemKey: {
            type: String,
            default: ''
        },
        itemIndex:{
            type: [String, Number],
            default: ''
        },
        optionType: String,
    },
    computed: {
        modelValue: {
            get () {
                return this.value
            },
            set (val) {
              // 需要弹窗的设置，就弹窗一下，然后本次值，不设定
              if (!this.checkDialogSetting(val)) {
                this.setSelectAnswer(val)
              }
            }
        },
        isDisabled () {
            return this.$route.query.formType === 'preview'
        }
    },
    mounted () {
      if (!this.modelValue && this.modelValue !== 0) {
        this.setDefaultAnswer();
      }
    },
    methods: {
      isShowOtherContent (item, index, type) {
        if (this.modelValue !== index) return false
        if (!item.ext) return false;
        if (!item.ext.type) return false;
        return item.ext.type.includes(type)
      },
      inputFocus (event, val) {
        this.inputValue = val;
      },
      inputBlur (event, index) {
        this.$set(this.options[index].ext, 'indexValue', this.inputValue)
        this.inputValue = ''
      },
      inputVal (val) {
        this.inputValue = val;
      },
      // 判断选项弹窗设置
      checkDialogSetting (val) {
        const item = this.options[val]
        if (item.dialogSetting && item.dialogSetting.dialogRestrictions) {
          this.$dialog.confirm({
            title: "提示",
            message: item.dialogSetting.content,
            showCancelButton: item.dialogSetting.dialogType == 1 ? false : true,
            confirmButtonText: "确认",
            cancelButtonText: "取消",
          }).then(() => {
            this.setDefaultAnswer()
          }).catch(() => {
            this.setSelectAnswer(val)
          })
          return true
        }
        return false
      },
      // 设置选择的答案
      setSelectAnswer (val) {
        this.$emit('input', val)
        this.$emit('sumBlur')
        if (this.options[val]) {
          this.$emit('scrollExam', this.options[val], this.itemIndex)
        }
      },
      // 设置默认答案
      setDefaultAnswer () {
        const defaultVal = this.options.findIndex(item => item.defaultFlag);
        if (defaultVal > -1) {
          this.$emit('input', defaultVal)
          const item = this.options[defaultVal]
          if (item) {
            this.$emit('scrollExam', item, this.itemIndex)
          }
        } else {
          this.$emit('input', '');
        }
        this.$emit('sumBlur')
      },
      // 点击查看附件
      clickShowFile (item) {
        this.$refs.formItem.clickShowFile({
          type: this.optionType, 
          list: [item.fileUrl]
        })
      }
    }
}
</script>
